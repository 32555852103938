import React, { useState, useEffect } from 'react'
import { Layout, Space, Image, Col, Divider, Menu, Dropdown } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone, faShip, faBookmark, faCamera, faBars } from '@fortawesome/free-solid-svg-icons'
import sliderImage from '../../assets/hakkimizda-9.jpeg'
import flagLogo from '../../assets/logo-v2.gif'

const SharedHeader: React.FC = () => {
    const [index, setIndex] = useState(0)
    const [open, setOpen] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const imageUrls = ['https://www.globalsailingacademy.com/wp-content/uploads/2021/10/hakkimizda-1-scaled.jpg']

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <a href="https://www.globalsailingacademy.com/">Anasayfa</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="https://www.globalsailingacademy.com/kurumsal/">Kurumsal</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a href="https://www.globalsailingacademy.com/galeri/">Galeri</a>
            </Menu.Item>
            <Menu.Item key="4">
                <a href="https://www.globalsailingacademy.com/iletisim/">İletişim</a>
            </Menu.Item>
        </Menu>
    )

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    return (
        <Layout style={{ position: screenWidth < 1000 ? 'relative' : 'absolute', width: '100%' }}>
            <Layout.Header
                style={
                    screenWidth < 400
                        ? { width: '100%', minHeight: '120px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : screenWidth < 600
                        ? { minHeight: '80px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : screenWidth < 1000
                        ? { minHeight: '80px', height: 'auto', background: '#f39200', lineHeight: 3, justifyContent: 'center', display: 'flex' }
                        : { height: '40px', background: '#f39200', lineHeight: 3 }
                }>
                <Layout
                    style={
                        screenWidth < 400
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '135px', width: '125px' }
                            : screenWidth < 600
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '130px', width: '125px' }
                            : screenWidth < 1000
                            ? { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '130px', width: '125px' }
                            : { position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '48px', marginLeft: '3%', width: '125px', height: 'auto' }
                    }>
                    {screenWidth < 1000 ? (
                        <Image alt="Logo" preview={false} src="https://www.globalsailingacademy.com/wp-content/uploads/2021/10/gsa-logo.png" />
                    ) : (
                        <Layout style={{ position: 'absolute', background: 'transparent', zIndex: '10', marginTop: '48px', marginLeft: '3%', width: '125px', height: 'auto' }}>
                            <Image alt="Flag" preview={false} src={flagLogo} />
                        </Layout>
                    )}
                </Layout>
                <Space
                    size="small"
                    // style={{

                    // }}>
                    style={screenWidth < 1000 ? { display: 'flex', position: 'absolute' } : { marginLeft: '65px', display: 'flex', position: 'absolute' }}>
                    <a href="https://www.instagram.com/globalsailingacademy/" className="youtube social">
                        <FontAwesomeIcon icon={faInstagram} size="lg" style={{ color: 'white' }} />
                    </a>
                    <a href="https://www.facebook.com/globalsailingacademy" className="facebook social">
                        <FontAwesomeIcon icon={faFacebook} size="lg" style={{ color: 'white' }} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCoyUN8Hcn2kUBv94CyQIBOA" className="youtube social">
                        <FontAwesomeIcon icon={faYoutube} size="lg" style={{ color: 'white' }} />
                    </a>
                </Space>
                {screenWidth < 1000 ? (
                    <div style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
                        <Col>
                            <FontAwesomeIcon icon={faEnvelope} size="1x" style={{ color: 'white', marginRight: '10px' }} />
                            <a
                                href="mailto:global@globalsailingacademy.com"
                                style={{ fontSize: screenWidth < 400 ? '12px' : '13x', color: '#ffffff', fontWeight: screenWidth < 400 ? 'bold' : 'normal' }}>
                                global@globalsailingacademy.com
                            </a>
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faPhone} size="1x" style={{ color: 'white', marginRight: '10px' }} />
                            <a href="tel:+90252 4655148" style={{ fontWeight: 'bold', color: '#ffffff' }}>
                                +90 252 465 5148
                            </a>
                        </Col>
                    </div>
                ) : (
                    <Space size="small" style={{ marginRight: '55px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faEnvelope} size="1x" style={{ color: 'white' }} />
                        <Col style={{ marginRight: '10px' }}>
                            <a href="mailto:global@globalsailingacademy.com" style={{ fontSize: '13x', color: '#ffffff' }}>
                                global@globalsailingacademy.com
                            </a>
                        </Col>
                        <FontAwesomeIcon icon={faPhone} size="1x" style={{ color: 'white' }} />
                        <Col>
                            <a href="tel:+90252 4655148" style={{ fontWeight: 'bold', color: '#ffffff' }}>
                                +90 252 465 5148
                            </a>
                        </Col>
                    </Space>
                )}
            </Layout.Header>
            {screenWidth < 1000 ? (
                <div
                    style={{
                        height: '170px',
                        background: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 1,
                        borderBottom: '3px solid #f39200',
                    }}>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            {/* <FontAwesomeIcon size="2x" style={{ color: '' }} icon={faBars} /> */}
                            <Image
                                width={25}
                                style={{ marginTop: '130px' }}
                                alt="hamburger-button"
                                preview={false}
                                src="https://www.globalsailingacademy.com/wp-content/uploads/2022/02/icon-menu-grey.png"
                            />
                        </a>
                    </Dropdown>
                </div>
            ) : (
                <div style={{ height: '80px', background: '#ffffff', display: 'flex', justifyContent: 'flex-end', zIndex: 1 }}>
                    <div style={{ marginTop: '30px', marginRight: '230px', paddingBottom: '10px' }}>
                        <a href="https://www.globalsailingacademy.com/" className="home-page-button" style={{ color: '#727475', fontSize: '17px', fontFamily: 'Montserrat' }}>
                            <FontAwesomeIcon icon={faShip} size="1x" style={{ color: '#727475', marginRight: '7px' }} />
                            Ana Sayfa
                        </a>
                        <Divider type="vertical" style={{ marginInline: '25px' }} />
                        <a
                            href="#"
                            onMouseEnter={() => setOpen(true)}
                            onMouseLeave={() => setOpen(false)}
                            className="home-page-button"
                            style={{ color: '#727475', fontSize: '17px', fontFamily: 'Montserrat' }}
                            onClick={() => setOpen(!open)}>
                            <FontAwesomeIcon icon={faBookmark} size="1x" style={{ color: '#727475', marginRight: '7px' }} />
                            Kurumsal
                        </a>
                        {open && (
                            <Menu
                                className="sub-menu"
                                style={{ position: 'absolute', marginLeft: '140px', fontSize: '17px', fontFamily: 'Montserrat' }}
                                onMouseEnter={() => setOpen(true)}
                                onMouseLeave={() => setOpen(false)}>
                                <Menu.Item className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3743">
                                    <a href="https://www.globalsailingacademy.com/hakkimizda/">Hakkımızda</a>
                                </Menu.Item>
                                <Menu.Item className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3832">
                                    <a href="https://www.globalsailingacademy.com/kurumsal/">Kurumsal</a>
                                </Menu.Item>
                                <Menu.Item className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3804">
                                    <a href="https://www.globalsailingacademy.com/tarihce/">Tarihçe</a>
                                </Menu.Item>
                                <Menu.Item className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3811">
                                    <a href="https://www.globalsailingacademy.com/insan-kaynaklari/">İnsan Kaynakları</a>
                                </Menu.Item>
                                <Menu.Item className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3861">
                                    <a href="https://www.globalsailingacademy.com/sosyal-sorumluluk/">Sosyal Sorumluluk</a>
                                </Menu.Item>
                            </Menu>
                        )}
                        <Divider type="vertical" style={{ marginInline: '25px' }} />
                        <a
                            href="https://www.globalsailingacademy.com/galeri/"
                            className="home-page-button"
                            style={{ color: '#727475', fontSize: '17px', fontFamily: 'Montserrat' }}>
                            <FontAwesomeIcon icon={faCamera} size="1x" style={{ color: '#727475', marginRight: '7px' }} />
                            Galeri
                        </a>
                        <Divider type="vertical" style={{ marginInline: '25px' }} />
                        <a
                            href="https://www.globalsailingacademy.com/iletisim/"
                            className="home-page-button"
                            style={{ color: '#727475', fontSize: '17px', fontFamily: 'Montserrat' }}>
                            <FontAwesomeIcon icon={faPhone} size="1x" style={{ color: '#727475', marginRight: '7px' }} />
                            İletişim
                        </a>
                    </div>
                </div>
            )}

            {screenWidth > 1000 ? (
                <Layout>
                    {' '}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        <Image preview={false} src={sliderImage} alt="slider image" style={{ minWidth: '1280px', width: '100%', height: '100%' }} />
                    </div>{' '}
                </Layout>
            ) : (
                <div></div>
            )}
        </Layout>
    )
}

export default SharedHeader
