import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Radio, DatePicker, Button, Space, Row, Col, Typography, Divider, Modal, Popconfirm } from 'antd'
import { GraphQLClient } from 'graphql-request'
import 'dayjs/locale/zh-cn'
import type { RadioChangeEvent, DatePickerProps } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import * as Mutations from '../libs/mutations'
import * as Queries from '../libs/queries'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/tr_TR'
import 'dayjs/locale/tr'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

interface Props {}

interface IForm {
    period: string
    name: string
    surname: string
    height: string
    city: string
    previousExperience: string
    boardingOrDay: string
    birthDate: string
    sex: string
    weight: string
    school: string
    grade: string
    hasparticipatedBefore: string
    parentName: string
    parentSurname: string
    parentPhone: string
    parentEmail: string
    parentCloseness: string
}

const ApplicationForm: React.FC<Props> = () => {
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [isExperience, setIsExperience] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [formNotValid, setFormNotValid] = useState(false)
    const [applicationsData, setApplicationsData] = useState<IForm>()

    const navigate = useNavigate()

    let params = useParams()
    const id = params.id

    const [formData, setFormData] = useState<IForm>({
        period: '',
        name: '',
        surname: '',
        height: '',
        city: '',
        previousExperience: '',
        boardingOrDay: '',
        birthDate: '',
        sex: '',
        weight: '',
        school: '',
        grade: '',
        hasparticipatedBefore: '',
        parentName: '',
        parentSurname: '',
        parentPhone: '',
        parentEmail: '',
        parentCloseness: '',
    })

    const handlePeriodChange = (value: string) => {
        setFormData({ ...formData, period: value })
    }

    const handleSexChange = (value: string) => {
        setFormData({ ...formData, sex: value })
    }

    const handleParticipateChange = (e: RadioChangeEvent) => {
        setFormData({ ...formData, hasparticipatedBefore: e.target.value })
    }

    const handleBoardingeChange = (e: RadioChangeEvent) => {
        setFormData({ ...formData, boardingOrDay: e.target.value })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const titleCasedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        setFormData({ ...formData, [e.target.name]: titleCasedValue })
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let phoneNumber = e.target.value

        if (phoneNumber.length < 1) {
            phoneNumber = '+'
        }
        if (!phoneNumber.startsWith('+')) {
            phoneNumber = '+' + phoneNumber
        }

        if (phoneNumber.length == 4 && phoneNumber.charAt(3) != ' ') {
            phoneNumber = phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 4)
        }

        if (phoneNumber.length == 8 && phoneNumber.charAt(7) != ' ') {
            phoneNumber = phoneNumber.slice(0, 7) + ' ' + phoneNumber.slice(7, 8)
        }

        if (phoneNumber.length == 12 && phoneNumber.charAt(11) != ' ') {
            phoneNumber = phoneNumber.slice(0, 11) + ' ' + phoneNumber.slice(11, 12)
        }

        if (phoneNumber.length > 16) phoneNumber = phoneNumber.slice(0, 16)

        setFormData({ ...formData, [e.target.name]: phoneNumber })
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            if (!isFormValid(formData)) {
                setFormNotValid(true)
                throw new Error()
            }
            setFormNotValid(false)
            setIsButtonDisabled(true)
            client.setHeader('authorization', localStorage.getItem('idToken')!)
            await client.request(Mutations.UpdateApplication, {
                ...formData,
                id: id,
            })
            setVisiblePopup(true)
            if (visiblePopup) {
                navigate('/applications')
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }
    const handleClosePopup = () => {
        setVisiblePopup(false)
        navigate('/applications')
    }

    const handleExperienceChange = (e: RadioChangeEvent) => {
        if (e.target.value === 'Evet') {
            setIsExperience(true)
        } else {
            setIsExperience(false)
            setFormData({ ...formData, previousExperience: '' })
        }
    }

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (formData.previousExperience.length < maxExperienceLength) {
            setFormData({ ...formData, previousExperience: e.target.value })
        } else {
            setFormData({ ...formData, previousExperience: e.target.value.slice(0, maxExperienceLength) })
        }
    }

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFormData({ ...formData, birthDate: dateString })
    }

    const isFormValid = (obj: IForm): boolean => {
        for (const [key, value] of Object.entries(obj)) {
            if (key !== 'previousExperience' && (!value || value === '')) {
                return false
            }
        }
        return true
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.GetApplication, {
                    ids: id,
                })
                setFormData(response.getApplication[0])
            } catch (e: any) {
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const checkIfExperience = () => {
            if (formData.previousExperience) {
                setIsExperience(true)
            }
        }
        checkIfExperience()
    })

    const errorJsx = (
        <>
            <Row>
                <Typography.Text type="danger" strong>
                    Bu formda doldurulabilir tüm alanlar zorunludur.
                </Typography.Text>
            </Row>
        </>
    )

    const maxExperienceLength = 500

    return (
        <div>
            <Typography.Title style={{ color: '#414445', textAlign: 'center', marginTop: '3rem', marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                BAŞVURU FORMU
            </Typography.Title>
            <Typography
                style={
                    screenWidth < 600
                        ? {
                              marginInline: '20px',
                          }
                        : screenWidth < 1000
                        ? {
                              marginInline: '70px',
                          }
                        : {
                              marginInline: '250px',
                          }
                }>
                Başvuru yapmak için aşağıdaki formu{' '}
                <u>
                    <b>doğru</b>
                </u>{' '}
                ve{' '}
                <u>
                    <b>eksiksiz</b>
                </u>{' '}
                bir şekilde doldurarak onaya göndermenizi rica ederiz. Onay bekleyen başvurular hakkında bizimle iletişime geçmeden önce{' '}
                <u>
                    <b>en az 2 iş günü</b>
                </u>{' '}
                beklemenizi önemle rica ederiz. Başvurunuz onaylandıktan sonra size{' '}
                <u>
                    <b>bilgilendirme mesajı</b>
                </u>{' '}
                iletilecek ve kayıt sürecine devam edebilmeniz için gerekli yönlendirme yapılacaktır.
            </Typography>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 14 }}
                    lg={{ span: 18 }}
                    md={{ span: 20 }}
                    sm={{ span: 20 }}
                    xs={{ span: 22 }}>
                    <Form onFinish={handleSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} labelAlign={'right'}>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Row justify="center" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Başvurulan Dönem</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item style={{ fontWeight: 'bold' }}>
                                        <Select
                                            style={{ minWidth: 327 }}
                                            value={formData.period}
                                            onChange={handlePeriodChange}
                                            options={[
                                                {
                                                    label: <span style={{ fontSize: 13, fontWeight: 'bold', color: '#414445' }}> Yatılı Kamp</span>,
                                                    options: [
                                                        { label: '30 Haziran - 9 Temmuz', value: '30 Haziran - 9 Temmuz' },
                                                        { label: '13 Temmuz - 22 Temmuz', value: '13 Temmuz - 22 Temmuz' },
                                                        { label: '26 Temmuz - 4 Ağustos', value: '26 Temmuz - 4 Ağustos' },
                                                        { label: '15 Ağustos - 24 Ağustos', value: '15 Ağustos - 24 Ağustos' },
                                                    ],
                                                },
                                                {
                                                    label: <span style={{ fontSize: 13, fontWeight: 'bold', color: '#414445' }}>Gündüzlü Kamp</span>,
                                                    options: [
                                                        { label: '24 Haziran - 28 Haziran', value: '24 Haziran - 28 Haziran' },
                                                        { label: '7 Ağustos - 11 Ağustos', value: '7 Ağustos - 11 Ağustos' },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>

                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Col>
                                    <Row justify="space-around" align="top">
                                        <div style={{ fontWeight: 'bold' }}>Konaklama Tipi</div>
                                    </Row>
                                    <Row justify="space-around" align="top">
                                        <Form.Item style={{ fontWeight: 'bold' }}>
                                            <Radio.Group value={formData.boardingOrDay} onChange={handleBoardingeChange}>
                                                <Space>
                                                    <Radio style={{ fontWeight: 'normal' }} value={'day'}>
                                                        Gündüzlü
                                                    </Radio>
                                                    <Radio style={{ fontWeight: 'normal' }} value={'boarding'}>
                                                        Yatılı
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        <Divider type="horizontal"></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            ÖĞRENCİNİN;
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 1 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input tabIndex={1} type="text" name="name" value={formData.name} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input tabIndex={3} type="text" name="surname" value={formData.surname} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Boyu (cm)">
                                    <Input tabIndex={5} type="text" name="height" value={formData.height} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Yaşadığı İl">
                                    <Input tabIndex={7} type="text" name="city" value={formData.city} onChange={handleChange} />
                                </Form.Item>
                                <Row>
                                    <Col span={12}>
                                        <div style={{ width: '90%', fontWeight: 'bold' }}>
                                            Geçmişte yelken
                                            <br />
                                            tecrübesi var mı?
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item>
                                            <Radio.Group value={isExperience ? 'Evet' : 'Hayır'} onChange={handleExperienceChange}>
                                                <Space>
                                                    <Radio value={'Evet'}>Evet</Radio>
                                                    <Radio value={'Hayır'}>Hayır</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    colon={false}
                                    label={
                                        <text style={{ fontWeight: 'bold' }}>
                                            Yelken
                                            <br /> Tecrübesi:
                                        </text>
                                    }>
                                    <TextArea
                                        disabled={!isExperience}
                                        onChange={handleTextAreaChange}
                                        rows={4}
                                        name="previousExperience"
                                        value={formData.previousExperience}
                                        style={!isExperience ? { backgroundColor: '#d6d6d6' } : {}}
                                        showCount={{
                                            formatter: ({ count }: { count: number }) => `${count} / ${maxExperienceLength}`,
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 12, pull: 1 }} lg={{ span: 12, pull: 1 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Tarihi">
                                    <DatePicker format={'DD-MM-YYYY'} locale={locale} onChange={onChange} value={dayjs(formData.birthDate, 'DD-MM-YYYY')} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Cinsiyeti">
                                    <Select tabIndex={4} value={formData.sex} onChange={handleSexChange}>
                                        <Select.Option value="male">Erkek</Select.Option>
                                        <Select.Option value="female">Kız</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kilosu">
                                    <Input tabIndex={6} type="text" name="weight" value={formData.weight} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Okulu">
                                    <Input tabIndex={8} type="text" name="school" value={formData.school} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Sınıfı">
                                    <Input tabIndex={10} type="text" name="grade" value={formData.grade} onChange={handleChange} />
                                </Form.Item>
                                <Col xs={{}} sm={{ push: 4 }} md={{ push: 5 }} lg={{ push: 5 }} xl={{ push: 5 }}>
                                    <Row justify="space-around" align="top">
                                        <div style={{ fontWeight: 'bold' }}>Kampımıza Daha Önce Katıldı Mı?</div>
                                    </Row>
                                    <Row justify="space-around" align="top">
                                        <Form.Item>
                                            <Radio.Group value={formData.hasparticipatedBefore} onChange={handleParticipateChange}>
                                                <Space>
                                                    <Radio value={'Evet'}>Evet</Radio>
                                                    <Radio value={'Hayır'}>Hayır</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            VELİNİN;
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, pull: 2 }} lg={{ span: 12, pull: 3 }} xl={{ span: 12, pull: 2 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input tabIndex={11} type="text" name="parentName" value={formData.parentName} onChange={(e) => handleChange(e)} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input tabIndex={13} type="text" name="parentSurname" value={formData.parentSurname} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14, pull: 1 }} lg={{ span: 12, pull: 1 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Telefon Numarası">
                                    <Input tabIndex={12} type="text" placeholder="+90 532 123 45 67" name="parentPhone" value={formData.parentPhone} onChange={handlePhoneChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Email">
                                    <Input tabIndex={14} type="text" name="parentEmail" value={formData.parentEmail} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Yakınlık Derecesi">
                                    <Input tabIndex={16} type="text" name="parentCloseness" value={formData.parentCloseness} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            {/* TODO: height kötü oldu, düzeltilecek */}
                            <Row style={{ justifyContent: 'center', marginTop: '20px', height: '60px' }}>
                                <Space size={'small'}>
                                    <Button
                                        disabled={isButtonDisabled}
                                        htmlType="submit"
                                        style={{ border: '1px solid #273D63', background: '#ffffff', color: '#273D63', marginTop: '20px', marginBottom: '40px' }}>
                                        Güncelle
                                    </Button>
                                    <Modal visible={visiblePopup} footer={null} onCancel={handleClosePopup}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography.Title level={4}>Global Sailing Academy Online Kayıt Sistemi</Typography.Title>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography>
                                                Başvurunuz için teşekkür ederiz. <br />
                                            </Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Formunuz bize ulaştı. Başvurunuz en kısa sürede değerlendirilerek tarafınıza geri dönüş yapılacaktır.</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Başvurunuzun durumunu ana sayfadaki listeden takip edebilirsiniz.</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography>Onaylanmamış başvurularınız hakkında bizimle iletişime geçmeden önce 2 iş günü beklemenizi önemle rica ederiz.</Typography>
                                        </div>
                                    </Modal>
                                    <Popconfirm
                                        title={<p>Güncellemeyi iptal etmek istediğinize emin misiniz?</p>}
                                        onConfirm={() => navigate('/applications')}
                                        okText="Evet"
                                        okButtonProps={{
                                            style: {
                                                background: '#273D63',
                                                color: '#ffffff',
                                            },
                                        }}
                                        cancelText="Hayır">
                                        <Button style={{ border: '1px solid #273D63', background: '#273D63', color: '#ffffff', marginTop: '20px', marginBottom: '40px' }}>
                                            İptal
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Row>
                            {formNotValid ? errorJsx : null}
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default ApplicationForm
