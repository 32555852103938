import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'

const { Text, Title } = Typography

const SignUp: React.FC = () => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [success, setSuccess] = useState('')
    const [reset, setReset] = useState(false)
    const [error, setError] = useState('')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const resetPassword = async () => {
        try {
            await authContext.changePassword(oldPassword, newPassword)
            setSuccess('Şifre değiştirme işlemi başarılı!')
            setTimeout(() => {
                navigate('/signin')
            }, 2000)
        } catch (err: any) {
            if (err instanceof Error) {
                setError('Hata! Tüm alanları doldurduğunuzdan ve doğru olduğundan emin olun.')
            }
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const errorJsx = (
        <>
            <Form.Item style={{ position: 'relative' }}>
                <Text type="danger" style={{ fontWeight: 'bold' }}>
                    {error}
                </Text>
            </Form.Item>
        </>
    )

    const successJsx = (
        <>
            <Form.Item style={{ position: 'relative' }}>
                <Text type="success" style={{ fontWeight: 'bold' }}>
                    {success}
                </Text>
            </Form.Item>
        </>
    )

    const resetPasswordApprovalJsx = (
        <>
            <Form.Item style={{ position: 'relative' }}>
                <Text type="success" style={{ fontWeight: 'bold' }}>
                    {error}
                </Text>
            </Form.Item>
        </>
    )

    const VerifyCodeJsx = (
        <>
            <Form
                name="normal_signup"
                className="signup-form"
                style={{
                    padding: '60px',
                    color: 'transparent',
                    backdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                    WebkitBackdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                    backgroundClip: 'padding-box',
                    border: '1px solid white',
                    borderRadius: '6rem',
                    overflow: 'hidden',
                    zIndex: '1',
                    objectPosition: 'flex',
                }}>
                <Form.Item name="oldPassword" rules={[{ required: true, message: 'Eski şifre boş bırakılamaz!' }]}>
                    <Input.Password placeholder="Eski Şifre" onChange={(e) => setOldPassword(e.target.value)} />
                </Form.Item>
                <Form.Item name="newPassword" rules={[{ required: true, message: 'Şifre boş bırakılamaz!' }]}>
                    <Input.Password placeholder="Yeni Şifre" onChange={(e) => setNewPassword(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Şifre onaylama boş bırakılamaz!' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject('Girilen iki şifre eşleşmiyor!')
                            },
                        }),
                    ]}>
                    <Input.Password placeholder="Yeni Şifreyi Onayla" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="signup-form-button" block onClick={resetPassword} style={{ background: '#f39200', marginTop: '20px' }}>
                        Şifreyi Sıfırla
                    </Button>
                </Form.Item>
                {success ? successJsx : null}
            </Form>
            {error ? errorJsx : null}
        </>
    )

    return (
        <Row justify="center" align="middle">
            <Col style={{ width: '400px' }}>
                <Title
                    style={{
                        textAlign: 'center',
                        fontSize: '35px',
                        marginTop: screenWidth < 600 ? '5rem' : '120px',
                    }}>
                    Şifre Değiştirme
                </Title>
                {reset ? resetPasswordApprovalJsx : VerifyCodeJsx}
            </Col>
        </Row>
    )
}

export default SignUp
