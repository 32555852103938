import React, { useState, useEffect } from 'react'
import { Table, Button, Space, Typography, Row, Col, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditOutlined, DeleteOutlined, RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { faSailboat, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { GraphQLClient } from 'graphql-request'
import * as Queries from '../libs/queries'
import * as Mutations from '../libs/mutations'

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

// interface Application {
//     key: number
//     name: string
//     period: string
//     state: string
// }

interface Props {}

type ApplicationType = {
    id: String
    name: String
    period: String
    state: String
}

const Applications: React.FC<Props> = () => {
    const navigate = useNavigate()
    const [isVisible, setVisibility] = useState(true)
    const [applicationsData, setApplicationsData] = useState<ApplicationType[]>()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.ApplicationsTableQuery)
                const data: ApplicationType[] = response.getAllApplications.map((application: any) => {
                    const registeration = response.getAllRegisterations.find((registeration: any) => registeration.id == application.id)

                    let appState = ''
                    switch (true) {
                        // check registerations first, if no registeration, switch case block will go to applications
                        case registeration?.state === 'CREATED':
                            appState = 'Kayıt Onay Bekliyor'
                            break
                        case registeration?.state === 'APPROVED':
                            appState = 'Kayıt Onaylandı'
                            break
                        case registeration?.state === 'REJECTED':
                            appState = 'Kayıt Onaylanmadı'
                            break

                        case application.state === 'CREATED':
                            appState = 'Onay Bekliyor'
                            break
                        case application.state === 'APPROVED':
                            appState = 'Başvuru Onaylandı'
                            break
                        case application.state === 'REJECTED':
                            appState = 'Başvuru Onaylanmadı'
                            break
                        default:
                            appState = 'Onay Bekliyor'
                            break
                        // TODO: extend
                    }
                    let appType = ''
                    switch (application.state) {
                        case 'CREATED':
                            appType = 'Yelken Kampı Başvurusu'
                            break
                        case 'APPROVED':
                            appType = 'Yelken Kampı Kayıdı'
                            break
                        default:
                            appType = 'Yelken Kampı Başvurusu'
                        // TODO: extend
                    }
                    return {
                        id: application.id,
                        name: application.name + ' ' + application.surname,
                        period: application.period,
                        state: appState,
                        type: appType,
                    }
                })

                // seek for direct-registrations
                const directRegistrations = response.getAllRegisterations.map((registration: any) => {
                    if (response.getAllApplications.find((application: any) => application.id == registration.id) == undefined) {
                        let appState = ''
                        switch (true) {
                            // check registerations first, if no registeration, switch case block will go to applications
                            case registration?.state === 'CREATED':
                                appState = 'Kayıt Onay Bekliyor'
                                break
                            case registration?.state === 'APPROVED':
                                appState = 'Kayıt Onaylandı'
                                break
                            case registration?.state === 'REJECTED':
                                appState = 'Kayıt Onaylanmadı'
                                break
                            default:
                                appState = 'Onay Bekliyor'
                                break
                        }

                        return {
                            id: registration.id,
                            name: registration.name + ' ' + registration.surname,
                            period: registration.period,
                            state: appState,
                            type: 'Şişli Terakki Okulları Yelkenle Tanışma Kampı',
                        }
                    } else return null
                })

                setApplicationsData(data.concat(directRegistrations).filter((obj) => obj !== null))
            } catch (e: any) {
                console.log(e.message)
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const handleDeleteApplication = async (id: string) => {
        try {
            client.setHeader('authorization', localStorage.getItem('idToken')!)
            const appResponse = await client.request(Mutations.DeleteApplication, {
                ids: [id],
            })
            if (appResponse) {
                setApplicationsData(applicationsData?.filter((obj) => obj.id !== id))
            }
            await client.request(Mutations.DeleteRegisteration, {
                ids: [id],
            })
        } catch (e: any) {
            throw new Error(e)
        }
    }

    const handleEdit = async (id: string) => {
        navigate(`/update/${id}`)
    }

    const handleRegister = async (id: string) => {
        navigate(`/register/${id}`)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibility(!isVisible)
        }, 500)
        return () => {
            clearInterval(intervalId)
        }
    }, [isVisible])

    const columns = [
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Dönem',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: 'İşlem Çeşidi',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'İşlem Durumu',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (record: any) => (
                <Space size="middle">
                    {record.state == 'Onay Bekliyor' && record.type == 'Yelken Kampı Başvurusu' ? (
                        <Button style={{ background: '#273D63' }} type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record.id)} />
                    ) : null}
                    <Popconfirm
                        title={<p>Başvuruyu silmek istediğine emin misin?</p>}
                        okButtonProps={{
                            style: {
                                background: '#273D63',
                                color: '#ffffff',
                            },
                        }}
                        onConfirm={() => handleDeleteApplication(record.id)}
                        okText="Evet"
                        cancelText="Hayır">
                        <Button type="dashed" icon={<DeleteOutlined />} />
                    </Popconfirm>
                    {record.state == 'Başvuru Onaylandı' ? (
                        <Button type="primary" style={{ background: '#f39200' }} icon={<RightOutlined />} onClick={() => handleRegister(record.id)}></Button>
                    ) : null}
                </Space>
            ),
        },
    ]

    let locale = {
        emptyText: 'Kayıtlı başvurunuz bulunmamaktadır',
    }

    return (
        <>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 20 }}
                    lg={{ span: 20 }}
                    md={{ span: 22 }}
                    sm={{ span: 22 }}
                    xs={{ span: 23 }}>
                    <Typography.Title
                        style={{
                            color: '#414445',
                            position: 'relative',
                            textAlign: 'center',
                            marginTop: screenWidth < 1000 ? '2rem' : '6rem',
                            marginBottom: 40,
                            fontWeight: 'bold',
                        }}>
                        GSA Yelken Kampları
                    </Typography.Title>
                    {applicationsData?.length == 0 && (
                        <div>
                            <Row style={{ justifyContent: 'center' }}>
                                <div
                                    style={{
                                        borderRadius: '20px',
                                        padding: '20px',
                                        background: '#273D63',
                                        border: '2px solid #414445',
                                        display: 'flex',
                                        textAlign: 'center',
                                    }}>
                                    <Typography style={{ color: '#ffffff', fontFamily: 'sans-serif', fontSize: '18px', marginInline: '1rem' }}>
                                        <div style={{ fontSize: '20px' }}> Online Kayıt Sistemimize Hoş Geldiniz!</div>
                                        <br />
                                        Yelken Kamplarımızın başvuru sürecini bu sayfadan yönetebilirsiniz. <br />
                                        <br />
                                        Yapmış olduğunuz başvuruların güncel durumunu aşağıdaki listeden takip edebilirsiniz.
                                        <br />
                                        <br /> Yeni bir başvuru oluşturmak için aşağıdaki butonu kullanabilirsiniz.
                                    </Typography>
                                </div>
                            </Row>
                        </div>
                    )}
                    <div style={{ textAlign: 'left', position: 'relative', marginInline: screenWidth < 400 ? '' : '4%' }}>
                        <Button
                            // onClick={() => navigate('/apply')}
                            onClick={() => {
                                navigate('/apply', { state: { campType: 'gsaYelkenKampi' } })
                            }}
                            style={{
                                margin: '2rem',
                                background: '#f39200',
                                color: '#ffffff',
                                marginBottom: '40px',
                                marginTop: '40px',
                                fontSize: '20px',
                                width: 'auto',
                                height: 'auto',
                            }}>
                            + GSA Yelken Kampına Kayıt Ol
                        </Button>
                        {/* <Button
                            // onClick={() => navigate('/apply')}
                            onClick={() => {
                                navigate('/direct-register')
                            }}
                            style={{
                                margin: '2rem',

                                background: '#273D63',
                                color: '#ffffff',
                                marginBottom: '40px',
                                marginTop: '40px',
                                fontSize: '20px',
                                width: 'auto',
                                height: 'auto',
                            }}>
                            + Şişli Terakki Okulları Yelkenle Tanışma Kampına Kayıt Ol
                        </Button> */}
                        <Button
                            onClick={() => {
                                // navigate with state
                                navigate('/apply', { state: { campType: 'maviMacera' } })
                            }}
                            style={{
                                margin: '2rem',

                                background: '#3E6D9C',
                                color: '#ffffff',
                                marginBottom: '40px',
                                marginTop: '40px',
                                fontSize: '20px',
                                width: 'auto',
                                height: 'auto',
                            }}>
                            + Mavi Macera Kampına Kayıt Ol
                        </Button>
                        <Row justify={'center'}>
                            <Typography style={{ fontSize: '2rem' }}>Kayıtlarım</Typography>
                        </Row>
                        <Table
                            style={{ alignSelf: 'center', marginTop: '30px', maxWidth: '100%', overflowX: 'scroll' }}
                            size={screenWidth < 400 ? 'small' : 'middle'}
                            locale={locale}
                            columns={columns}
                            dataSource={applicationsData}
                        />
                    </div>
                    <Row style={{ justifyContent: 'center', marginBottom: '8rem' }}>
                        <div
                            style={{
                                borderRadius: '20px',
                                padding: '10px',
                                marginTop: '30px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="3x"
                                style={{ visibility: isVisible ? 'visible' : 'hidden', color: '#ffbf00', marginRight: '10px', animation: 'blink 1s linear infinite' }}
                            />
                            <Typography style={{ fontFamily: 'sans-serif', fontSize: '18px', marginLeft: '10px' }}>
                                Onay bekleyen başvurularınız hakkında bizimle iletişime geçmeden önce en az 2 iş günü beklemenizi önemle rica ederiz.
                            </Typography>
                        </div>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Applications
