import gql from 'graphql-tag'

export const CreateApplication = gql`
    mutation CreateApplication(
        $birthDate: String!,
        $boardingOrDay: String!,
        $city: String!,
        $grade: String!,
        $hasparticipatedBefore: String!,
        $height: String!,
        $name: String!,
        $parentCloseness: String!,
        $parentEmail: String!,
        $parentName: String!,
        $parentPhone: String!,
        $parentSurname: String!,
        $period: String!,
        $previousExperience: String!,
        $school: String!,
        $sex: String!,
        $surname: String!,
        $weight: String!
    ) {
        putApplication(
            birthDate: $birthDate,
            boardingOrDay: $boardingOrDay,
            city: $city,
            grade: $grade,
            hasparticipatedBefore: $hasparticipatedBefore,
            height: $height,
            name: $name,
            parentCloseness: $parentCloseness,
            parentEmail: $parentEmail,
            parentName: $parentName,
            parentPhone: $parentPhone,
            parentSurname: $parentSurname,
            period: $period,
            previousExperience: $previousExperience,
            school: $school,
            sex: $sex,
            surname: $surname,
            weight: $weight
        ) {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
        }
    }
`

export const UpdateApplication = gql`
    mutation UpdateApplication(
        $birthDate: String!,
        $boardingOrDay: String!,
        $city: String!,
        $grade: String!,
        $hasparticipatedBefore: String!,
        $height: String!,
        $id: ID!,
        $name: String!,
        $parentCloseness: String!,
        $parentEmail: String!,
        $parentName: String!,
        $parentPhone: String!,
        $parentSurname: String!,
        $period: String!,
        $previousExperience: String!,
        $school: String!,
        $sex: String!,
        $surname: String!,
        $weight: String!
    ) {
        updateApplication(
            birthDate: $birthDate,
            boardingOrDay: $boardingOrDay,
            city: $city,
            grade: $grade,
            hasparticipatedBefore: $hasparticipatedBefore,
            height: $height,
            id: $id,
            name: $name,
            parentCloseness: $parentCloseness,
            parentEmail: $parentEmail,
            parentName: $parentName,
            parentPhone: $parentPhone,
            parentSurname: $parentSurname,
            period: $period,
            previousExperience: $previousExperience,
            school: $school,
            sex: $sex,
            surname: $surname,
            weight: $weight
        ) {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
        }
    }
`

export const DeleteApplication = gql`
    mutation DeleteApplication($ids: [ID]!) {
        deleteApplication(ids: $ids)
    }
`

export const UpdateRegisteration = gql`
    mutation UpdateRegisteration(
        $id: ID!

        $period: String!
        $boardingOrDay: String!
        $classType: String!
        $firstOrAgain: String!

        $name: String!
        $surname: String!
        $sex: String!
        $citizenId: String!
        $birthDate: String!
        $birthPlace: String!

        $school: String!
        $grade: String!
        $city: String!

        $address: String!
        $homePhone: String!
        $studentPhone: String!
        $studentEmail: String!
        $motherNameSurname: String!
        $fatherNameSurname: String!

        $bloodType: String!
        $height: String!
        $weight: String!
        $studentAllergy: String!
        $studentAllergicFoods: String!
        $studentHealthIssues: String!
        $studentUseOfMedicine: String!
        $studentSideEffectMedicine: String!
        $parentsMarried: String!
        $firstTimeAway: String!
        $bedwettingProblem: String!
        $sleepwalkingProblem: String!

        $doesHaveASportLicence: String!
        $whichSport: String!
        $haveEverJoinedASummerCampBefore: String!
        $whereWasCamp: String!
        $doesHaveFearOfSea: String!
        $haveEverRideABoat: String!
        $swimmingDegree: String!
        $ifOptimistWhereWhatDegree: String!
        $ifLaserWhereWhatDegree: String!

        $parentName: String!
        $parentSurname: String!
        $parentCloseness: String!
        $parentJob: String!
        $parentHomeAddress: String!
        $parentWorkAddress: String!
        $parentHomePhone: String!
        $parentWorkPhone: String!
        $parentCitizenId: String!
        $parentEmail: String!
        $motherPhone: String!
        $fatherPhone: String!

        $invoiceName: String!
        $invoiceAddress: String!
        $taxAddress: String!
        $taxNumber: String!

        $ideasAndThoughts: String!
        $importantInfoAboutStudent: String!

        $hasparticipatedBefore: String!
    ) {
        updateRegisteration(
            id: $id

            period: $period
            boardingOrDay: $boardingOrDay
            classType: $classType
            firstOrAgain: $firstOrAgain

            name: $name
            surname: $surname
            sex: $sex
            citizenId: $citizenId
            birthDate: $birthDate
            birthPlace: $birthPlace

            school: $school
            grade: $grade
            city: $city

            address: $address
            homePhone: $homePhone
            studentPhone: $studentPhone
            studentEmail: $studentEmail
            motherNameSurname: $motherNameSurname
            fatherNameSurname: $fatherNameSurname

            bloodType: $bloodType
            height: $height
            weight: $weight
            studentAllergy: $studentAllergy
            studentAllergicFoods: $studentAllergicFoods
            studentHealthIssues: $studentHealthIssues
            studentUseOfMedicine: $studentUseOfMedicine
            studentSideEffectMedicine: $studentSideEffectMedicine
            parentsMarried: $parentsMarried
            firstTimeAway: $firstTimeAway
            bedwettingProblem: $bedwettingProblem
            sleepwalkingProblem: $sleepwalkingProblem

            doesHaveASportLicence: $doesHaveASportLicence
            whichSport: $whichSport
            haveEverJoinedASummerCampBefore: $haveEverJoinedASummerCampBefore
            whereWasCamp: $whereWasCamp
            doesHaveFearOfSea: $doesHaveFearOfSea
            haveEverRideABoat: $haveEverRideABoat
            swimmingDegree: $swimmingDegree
            ifOptimistWhereWhatDegree: $ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree: $ifLaserWhereWhatDegree

            parentName: $parentName
            parentSurname: $parentSurname
            parentCloseness: $parentCloseness
            parentJob: $parentJob
            parentHomeAddress: $parentHomeAddress
            parentWorkAddress: $parentWorkAddress
            parentHomePhone: $parentHomePhone
            parentWorkPhone: $parentWorkPhone
            parentCitizenId: $parentCitizenId
            parentEmail: $parentEmail
            motherPhone: $motherPhone
            fatherPhone: $fatherPhone

            invoiceName: $invoiceName
            invoiceAddress: $invoiceAddress
            taxAddress: $taxAddress
            taxNumber: $taxNumber

            ideasAndThoughts: $ideasAndThoughts
            importantInfoAboutStudent: $importantInfoAboutStudent

            hasparticipatedBefore: $hasparticipatedBefore
        ) {
            id

            period
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
        }
    }
`


export const PutRegisteration = gql`
    mutation PutRegisteration(
        $id: ID!

        $period: String!
        $boardingOrDay: String!
        $classType: String!
        $firstOrAgain: String!

        $name: String!
        $surname: String!
        $sex: String!
        $citizenId: String!
        $birthDate: String!
        $birthPlace: String!

        $school: String!
        $grade: String!
        $city: String!

        $address: String!
        $homePhone: String!
        $studentPhone: String!
        $studentEmail: String!
        $motherNameSurname: String!
        $fatherNameSurname: String!

        $bloodType: String!
        $height: String!
        $weight: String!
        $studentAllergy: String!
        $studentAllergicFoods: String!
        $studentHealthIssues: String!
        $studentUseOfMedicine: String!
        $studentSideEffectMedicine: String!
        $parentsMarried: String!
        $firstTimeAway: String!
        $bedwettingProblem: String!
        $sleepwalkingProblem: String!

        $doesHaveASportLicence: String!
        $whichSport: String!
        $haveEverJoinedASummerCampBefore: String!
        $whereWasCamp: String!
        $doesHaveFearOfSea: String!
        $haveEverRideABoat: String!
        $swimmingDegree: String!
        $ifOptimistWhereWhatDegree: String!
        $ifLaserWhereWhatDegree: String!

        $parentName: String!
        $parentSurname: String!
        $parentCloseness: String!
        $parentJob: String!
        $parentHomeAddress: String!
        $parentWorkAddress: String!
        $parentHomePhone: String!
        $parentWorkPhone: String!
        $parentCitizenId: String!
        $parentBirthDate: String!
        $parentEmail: String!
        $motherPhone: String!
        $fatherPhone: String!

        $invoiceName: String!
        $invoiceAddress: String!
        $taxAddress: String!
        $taxNumber: String!

        $ideasAndThoughts: String!
        $importantInfoAboutStudent: String!

        $hasparticipatedBefore: String!
    ) {
        putRegisteration(
            id: $id

            period: $period
            boardingOrDay: $boardingOrDay
            classType: $classType
            firstOrAgain: $firstOrAgain

            name: $name
            surname: $surname
            sex: $sex
            citizenId: $citizenId
            birthDate: $birthDate
            birthPlace: $birthPlace

            school: $school
            grade: $grade
            city: $city

            address: $address
            homePhone: $homePhone
            studentPhone: $studentPhone
            studentEmail: $studentEmail
            motherNameSurname: $motherNameSurname
            fatherNameSurname: $fatherNameSurname

            bloodType: $bloodType
            height: $height
            weight: $weight
            studentAllergy: $studentAllergy
            studentAllergicFoods: $studentAllergicFoods
            studentHealthIssues: $studentHealthIssues
            studentUseOfMedicine: $studentUseOfMedicine
            studentSideEffectMedicine: $studentSideEffectMedicine
            parentsMarried: $parentsMarried
            firstTimeAway: $firstTimeAway
            bedwettingProblem: $bedwettingProblem
            sleepwalkingProblem: $sleepwalkingProblem

            doesHaveASportLicence: $doesHaveASportLicence
            whichSport: $whichSport
            haveEverJoinedASummerCampBefore: $haveEverJoinedASummerCampBefore
            whereWasCamp: $whereWasCamp
            doesHaveFearOfSea: $doesHaveFearOfSea
            haveEverRideABoat: $haveEverRideABoat
            swimmingDegree: $swimmingDegree
            ifOptimistWhereWhatDegree: $ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree: $ifLaserWhereWhatDegree

            parentName: $parentName
            parentSurname: $parentSurname
            parentCloseness: $parentCloseness
            parentJob: $parentJob
            parentHomeAddress: $parentHomeAddress
            parentWorkAddress: $parentWorkAddress
            parentHomePhone: $parentHomePhone
            parentWorkPhone: $parentWorkPhone
            parentCitizenId: $parentCitizenId
            parentBirthDate: $parentBirthDate
            parentEmail: $parentEmail
            motherPhone: $motherPhone
            fatherPhone: $fatherPhone

            invoiceName: $invoiceName
            invoiceAddress: $invoiceAddress
            taxAddress: $taxAddress
            taxNumber: $taxNumber

            ideasAndThoughts: $ideasAndThoughts
            importantInfoAboutStudent: $importantInfoAboutStudent

            hasparticipatedBefore: $hasparticipatedBefore
        ) {
            id

            period
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentBirthDate
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
        }
    }
`


export const PutDirectRegisteration = gql`
    mutation PutDirectRegisteration(

        $period: String!

        $name: String!
        $surname: String!
        $sex: String!
        $citizenId: String!
        $birthDate: String!
        $birthPlace: String!

        $school: String!
        $grade: String!
        $city: String!

        $address: String!
        $homePhone: String!
        $studentPhone: String!
        $studentEmail: String!
        $motherNameSurname: String!
        $fatherNameSurname: String!

        $bloodType: String!
        $height: String!
        $weight: String!
        $studentAllergy: String!
        $studentAllergicFoods: String!
        $studentHealthIssues: String!
        $studentUseOfMedicine: String!
        $studentSideEffectMedicine: String!

        $doesHaveASportLicence: String!
        $whichSport: String!
        $haveEverRideABoat: String!
        $ifOptimistWhereWhatDegree: String!
        $ifLaserWhereWhatDegree: String!

        $parentName: String!
        $parentSurname: String!
        $parentCloseness: String!
        $parentJob: String!
        $parentHomeAddress: String!
        $parentWorkAddress: String!
        $parentHomePhone: String!
        $parentWorkPhone: String!
        $parentCitizenId: String!
        $parentBirthDate: String!
        $parentEmail: String!
        $motherPhone: String!
        $fatherPhone: String!

        $invoiceName: String!
        $invoiceAddress: String!
        $taxAddress: String!
        $taxNumber: String!

        $ideasAndThoughts: String!
        $importantInfoAboutStudent: String!
    ) {
        putDirectRegisteration(

            period: $period

            name: $name
            surname: $surname
            sex: $sex
            citizenId: $citizenId
            birthDate: $birthDate
            birthPlace: $birthPlace

            school: $school
            grade: $grade
            city: $city

            address: $address
            homePhone: $homePhone
            studentPhone: $studentPhone
            studentEmail: $studentEmail
            motherNameSurname: $motherNameSurname
            fatherNameSurname: $fatherNameSurname

            bloodType: $bloodType
            height: $height
            weight: $weight
            studentAllergy: $studentAllergy
            studentAllergicFoods: $studentAllergicFoods
            studentHealthIssues: $studentHealthIssues
            studentUseOfMedicine: $studentUseOfMedicine
            studentSideEffectMedicine: $studentSideEffectMedicine

            doesHaveASportLicence: $doesHaveASportLicence
            whichSport: $whichSport
            haveEverRideABoat: $haveEverRideABoat
            ifOptimistWhereWhatDegree: $ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree: $ifLaserWhereWhatDegree

            parentName: $parentName
            parentSurname: $parentSurname
            parentCloseness: $parentCloseness
            parentJob: $parentJob
            parentHomeAddress: $parentHomeAddress
            parentWorkAddress: $parentWorkAddress
            parentHomePhone: $parentHomePhone
            parentWorkPhone: $parentWorkPhone
            parentCitizenId: $parentCitizenId
            parentBirthDate: $parentBirthDate
            parentEmail: $parentEmail
            motherPhone: $motherPhone
            fatherPhone: $fatherPhone

            invoiceName: $invoiceName
            invoiceAddress: $invoiceAddress
            taxAddress: $taxAddress
            taxNumber: $taxNumber

            ideasAndThoughts: $ideasAndThoughts
            importantInfoAboutStudent: $importantInfoAboutStudent
        ) {

            period

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine

            doesHaveASportLicence
            whichSport
            haveEverRideABoat
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentBirthDate
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent
        }
    }
`


export const DeleteRegisteration = gql`
    mutation DeleteRegisteration($ids: [ID]!) {
        deleteRegisteration(ids: $ids)
    }
`