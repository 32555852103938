import gql from 'graphql-tag'

export const GetAllApplications = gql`
    query GetAllApplications {
        getAllApplications {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
            state
        }
    }
`

export const GetRegisteration = gql`
    query GetRegisteration($ids: [ID]!) {
        getRegisteration(ids: $ids) {
            id

            period
            time
            boardingOrDay
            classType
            firstOrAgain

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine
            parentsMarried
            firstTimeAway
            bedwettingProblem
            sleepwalkingProblem

            doesHaveASportLicence
            whichSport
            haveEverJoinedASummerCampBefore
            whereWasCamp
            doesHaveFearOfSea
            haveEverRideABoat
            swimmingDegree
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentBirthDate
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
        }
    }
`

export const GetDirectRegisteration = gql`
    query GetDirectRegisteration($ids: [ID]!) {
        getDirectRegisteration(ids: $ids) {
            id

            period

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine

            doesHaveASportLicence
            whichSport
            haveEverRideABoat
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentBirthDate
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
        }
    }
`

export const GetAllRegisterations = gql`
    query GetAllRegisterations {
        getAllRegisterations {
                        id

            period

            name
            surname
            sex
            citizenId
            birthDate
            birthPlace

            school
            grade
            city

            address
            homePhone
            studentPhone
            studentEmail
            motherNameSurname
            fatherNameSurname

            bloodType
            height
            weight
            studentAllergy
            studentAllergicFoods
            studentHealthIssues
            studentUseOfMedicine
            studentSideEffectMedicine

            doesHaveASportLicence
            whichSport
            haveEverRideABoat
            ifOptimistWhereWhatDegree
            ifLaserWhereWhatDegree

            parentName
            parentSurname
            parentCloseness
            parentJob
            parentHomeAddress
            parentWorkAddress
            parentHomePhone
            parentWorkPhone
            parentCitizenId
            parentBirthDate
            parentEmail
            motherPhone
            fatherPhone

            invoiceName
            invoiceAddress
            taxAddress
            taxNumber

            ideasAndThoughts
            importantInfoAboutStudent

            hasparticipatedBefore
        }
    }
`

export const GetApplication = gql`
    query GetApplication($ids: [ID]!) {
        getApplication(ids: $ids) {
            birthDate
            boardingOrDay
            city
            grade
            hasparticipatedBefore
            height
            id
            name
            parentCloseness
            parentEmail
            parentName
            parentPhone
            parentSurname
            period
            previousExperience
            school
            sex
            surname
            weight
        }
    }
`

export const ApplicationsTableQuery = gql`
    query GetAllApplications {
        getAllApplications {
            id
            name
            period
            surname
            state
        }
        getAllRegisterations {
            id
            name
            period
            surname
            state
        }
    }
`