import './App.css'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import AuthProvider, { SignedIn, SignedOut } from './contexts/authContext'

import SharedHeader from './routes/shared/Header'
import Footer from './routes/shared/Footer'
import LoginHeader from './routes/shared/LoginHeader'
import LoginFooter from './routes/shared/LoginFooter'
import SignIn from './routes/SignIn'
import SignUp from './routes/SignUp'
import Applications from './routes/Applications'
import UpdateApplication from './routes/UpdateApplication'
import VerifyRequestCode from './routes/VerifyRequestCode'
import ResetPassword from './routes/ResetPassword'
import Apply from './routes/Apply'
import DirectRegister from './routes/DirectRegister'
import ChangeOldPassword from './routes/ChangeOldPasword'
import PreRegistrationForm from './routes/PreRegistrationForm'
// import FormHandler from './routes/register/Register'
import { ConfigProvider } from 'antd'

function App() {
    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: 'Montserrat',
                    },
                }}>
                <BrowserRouter>
                    <AuthProvider>
                        <SignedIn>
                            <Routes>
                                <Route
                                    path="applications"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <Applications />
                                            <Footer />
                                        </>
                                    }
                                />
                                <Route
                                    path="apply"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <Apply />
                                            <Footer />
                                        </>
                                    }
                                />
                                <Route
                                    path="direct-register"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <DirectRegister />
                                            <Footer />
                                        </>
                                    }
                                />
                                <Route
                                    path="register/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <PreRegistrationForm />
                                            <Footer />
                                        </>
                                    }
                                />
                                {/* <Route
                                    path="register-test/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <FormHandler />
                                            <Footer />
                                        </>
                                    }
                                /> */}
                                <Route
                                    path="update/:id"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <UpdateApplication />
                                            <Footer />
                                        </>
                                    }
                                />
                                <Route
                                    path="/changePassword"
                                    element={
                                        <>
                                            <SharedHeader />
                                            <ChangeOldPassword />
                                            <Footer />
                                        </>
                                    }
                                />
                                <Route path="application/:id" element={<Apply />} />
                                <Route path="*" element={<Navigate to="/applications" />} />
                            </Routes>
                        </SignedIn>
                        <SignedOut>
                            <Routes>
                                <Route
                                    path="/register"
                                    element={
                                        <>
                                            <LoginHeader />
                                            <SignUp />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route
                                    path="/signin"
                                    element={
                                        <>
                                            <LoginHeader />
                                            <SignIn />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route
                                    path="/forgotpassword"
                                    element={
                                        <>
                                            <LoginHeader />
                                            <ResetPassword />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route
                                    path="/verifycode"
                                    element={
                                        <>
                                            <LoginHeader />
                                            <VerifyRequestCode />
                                            <LoginFooter />
                                        </>
                                    }
                                />
                                <Route path="*" element={<Navigate to="/signin" />} />
                            </Routes>
                        </SignedOut>
                    </AuthProvider>
                </BrowserRouter>
            </ConfigProvider>
        </>
    )
}

export default App
