import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Typography, Popconfirm } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'

const { Title } = Typography

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const [email, setEmail] = useState('')
    const [emailSubmit, setEmailSubmit] = useState(false)

    const authContext = useContext(AuthContext)

    const emailInputSubmited = async () => {
        setEmailSubmit(true)
    }

    const requestCodeSubmitted = async () => {
        try {
            await authContext.sendCode(email)
            navigate('/verifycode')
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const emailVerifyJsx = (
        <Form
            onFinish={emailInputSubmited}
            style={{
                padding: '50px',
                color: 'transparent',
                backdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                WebkitBackdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                backgroundClip: 'padding-box',
                border: '1px solid white',
                borderRadius: '2rem',
                overflow: 'hidden',
                marginBottom: '4rem',
            }}>
            <Form.Item name="email" style={{ marginBottom: '50px' }} rules={[{ required: true, message: `Email boş bırakılamaz!` }]}>
                <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Popconfirm title={<p>Reset şifresini {email} adresine göndermek istediğine emin misin?</p>} onConfirm={requestCodeSubmitted} okText="Evet" cancelText="Hayır">
                <Form.Item>
                    <Button htmlType="submit" className="forgotpassword-form-button" block style={{ background: '#f39200' }}>
                        Gönder
                    </Button>
                </Form.Item>
            </Popconfirm>
            <Form.Item>
                <Link
                    to="/signin"
                    style={{ color: '#00004d', textAlign: 'center', fontWeight: 'bold', textDecoration: 'underline' }}
                    onMouseEnter={(e) => {
                        ;(e.target as HTMLLinkElement).style.color = '#f39200'
                    }}
                    onMouseLeave={(e) => {
                        ;(e.target as HTMLLinkElement).style.color = '#00004d'
                    }}>
                    Giriş Ekranına Dön
                </Link>
            </Form.Item>
        </Form>
    )

    return (
        <Row justify="center" align="middle" style={{}}>
            <Col>
                <Title style={screenWidth < 1000 ? { textAlign: 'center', fontSize: '35px' } : { textAlign: 'center', marginTop: '10rem', fontSize: '35px', color: '#2b395f' }}>
                    Şifre Sıfırlama
                </Title>
                {emailVerifyJsx}
            </Col>
        </Row>
    )
}

export default ForgotPassword
