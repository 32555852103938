import React, { useState, useEffect, useRef, Ref } from 'react'
import { Form, Select, Input, Radio, DatePicker, Button, Space, Row, Col, Typography, Divider, Modal, Popconfirm, Checkbox, InputRef } from 'antd'
import { GraphQLClient } from 'graphql-request'
import 'dayjs/locale/zh-cn'
import type { RadioChangeEvent, DatePickerProps } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import * as Mutations from '../libs/mutations'
import * as Queries from '../libs/queries'
import locale from 'antd/es/date-picker/locale/tr_TR'
import 'dayjs/locale/tr'

const { TextArea } = Input

const { Text } = Typography

const client = new GraphQLClient('https://zpldzgwp25frhn34n4bzrikqsi.appsync-api.eu-west-1.amazonaws.com/graphql')

interface Props {}

interface IForm {
    period: string
    time: string
    boardingOrDay: string
    classType: string
    firstOrAgain: string

    name: string
    surname: string
    sex: string
    citizenId: string
    birthDate: string
    birthPlace: string

    school: string
    grade: string
    city: string

    address: string
    homePhone: string
    studentPhone: string
    studentEmail: string
    motherNameSurname: string
    fatherNameSurname: string

    bloodType: string
    height: string
    weight: string
    studentAllergy: string
    studentAllergicFoods: string
    studentHealthIssues: string
    studentUseOfMedicine: string
    studentSideEffectMedicine: string
    parentsMarried: string
    firstTimeAway: string
    bedwettingProblem: string
    sleepwalkingProblem: string

    doesHaveASportLicence: string
    whichSport: string
    haveEverJoinedASummerCampBefore: string
    whereWasCamp: string
    doesHaveFearOfSea: string
    haveEverRideABoat: string
    swimmingDegree: string
    ifOptimistWhereWhatDegree: string
    ifLaserWhereWhatDegree: string

    parentName: string
    parentSurname: string
    parentCloseness: string
    parentCitizenId: string
    parentBirthDate: string
    parentJob: string
    parentHomeAddress: string
    parentWorkAddress: string
    parentHomePhone: String
    parentWorkPhone: string
    parentEmail: string
    motherPhone: string
    fatherPhone: string

    invoiceName: string
    invoiceAddress: string
    taxAddress: string
    taxNumber: string

    ideasAndThoughts: string
    importantInfoAboutStudent: string

    hasparticipatedBefore: string
}

const ApplicationForm: React.FC<Props> = () => {
    const [visible, setVisible] = useState(false)
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [checked, setChecked] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [isExperience, setIsExperience] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [error, setError] = useState('')
    const [triggerRequest, setTriggerRequest] = useState(false)
    const [period, setPeriod] = useState(false)
    const [formNotValid, setFormNotValid] = useState(false)

    let params = useParams()
    const id = params.id

    const citizenId = useRef<InputRef>(null)
    const birthPlace = useRef<InputRef>(null)

    const school = useRef<InputRef>(null)
    const grade = useRef<InputRef>(null)
    const city = useRef<InputRef>(null)

    const address = useRef<InputRef>(null)
    const homePhone = useRef<InputRef>(null)
    const studentPhone = useRef<InputRef>(null)
    const studentEmail = useRef<InputRef>(null)
    const motherNameSurname = useRef<InputRef>(null)
    const fatherNameSurname = useRef<InputRef>(null)

    const bloodType = useRef<InputRef>(null)
    const height = useRef<InputRef>(null)
    const weight = useRef<InputRef>(null)
    const studentAllergy = useRef<InputRef>(null)
    const studentAllergicFoods = useRef<InputRef>(null)
    const studentHealthIssues = useRef<InputRef>(null)
    const studentUseOfMedicine = useRef<InputRef>(null)
    const studentSideEffectMedicine = useRef<InputRef>(null)

    const whichSport = useRef<InputRef>(null)
    const whereWasCamp = useRef<InputRef>(null)
    const ifOptimistWhereWhatDegree = useRef<InputRef>(null)
    const ifLaserWhereWhatDegree = useRef<InputRef>(null)

    const parentName = useRef<InputRef>(null)
    const parentSurname = useRef<InputRef>(null)
    const parentCloseness = useRef<InputRef>(null)
    const parentCitizenId = useRef<InputRef>(null)
    const parentJob = useRef<InputRef>(null)
    const parentHomeAddress = useRef<InputRef>(null)
    const parentWorkAddress = useRef<InputRef>(null)
    const parentHomePhone = useRef<InputRef>(null)
    const parentWorkPhone = useRef<InputRef>(null)
    const parentEmail = useRef<InputRef>(null)
    const motherPhone = useRef<InputRef>(null)
    const fatherPhone = useRef<InputRef>(null)

    const invoiceName = useRef<InputRef>(null)
    const invoiceAddress = useRef<InputRef>(null)
    const taxAddress = useRef<InputRef>(null)
    const taxNumber = useRef<InputRef>(null)

    const ideasAndThoughts = useRef<InputRef>(null)
    const importantInfoAboutStudent = useRef<InputRef>(null)

    const navigate = useNavigate()

    const [formData, setFormData] = useState<IForm>({
        period: '',
        time: '',
        boardingOrDay: '',
        classType: '',
        firstOrAgain: '',

        //
        name: '',
        surname: '',
        sex: '',
        citizenId: '',
        birthDate: '',
        birthPlace: '',

        //school info
        school: '',
        grade: '',
        city: '',

        //contanct info
        address: '',
        homePhone: '',
        studentPhone: '',
        studentEmail: '',
        motherNameSurname: '',
        fatherNameSurname: '',

        //Health Info
        bloodType: '',
        height: '',
        weight: '',
        studentAllergy: '',
        studentAllergicFoods: '',
        studentHealthIssues: '',
        studentUseOfMedicine: '',
        studentSideEffectMedicine: '',
        parentsMarried: '',
        firstTimeAway: '',
        bedwettingProblem: '',
        sleepwalkingProblem: '',

        //Sport and Sea Experience
        doesHaveASportLicence: '',
        whichSport: '',
        haveEverJoinedASummerCampBefore: '',
        whereWasCamp: '',
        doesHaveFearOfSea: '',
        haveEverRideABoat: '',
        swimmingDegree: '',
        ifOptimistWhereWhatDegree: '',
        ifLaserWhereWhatDegree: '',

        //Parent Info
        parentName: '',
        parentSurname: '',
        parentCloseness: '',
        parentCitizenId: '',
        parentBirthDate: '',
        parentJob: '',
        parentHomeAddress: '',
        parentWorkAddress: '',
        parentHomePhone: '',
        parentWorkPhone: '',
        parentEmail: '',
        motherPhone: '',
        fatherPhone: '',

        //Invoice Info
        invoiceName: '',
        invoiceAddress: '',
        taxAddress: '',
        taxNumber: '',

        //Ideas and Thougts Info
        ideasAndThoughts: '',
        importantInfoAboutStudent: '',

        hasparticipatedBefore: '',
    })

    const handleCheckbox = (e: any) => {
        setChecked(e.target.checked)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setChecked(!checked)
            event.preventDefault()
        }
    }

    const handleEverRideBot = (value: string) => {
        setFormData({ ...formData, haveEverRideABoat: value })
    }

    const handleSwimmingDegree = (value: string) => {
        setFormData({ ...formData, swimmingDegree: value })
    }

    const handleParentsMarried = (e: RadioChangeEvent) => {
        setFormData({ ...formData, parentsMarried: e.target.value })
    }

    const handleFirstTimeAway = (e: RadioChangeEvent) => {
        setFormData({ ...formData, firstTimeAway: e.target.value })
    }

    const handleBedwettingProblem = (e: RadioChangeEvent) => {
        setFormData({ ...formData, bedwettingProblem: e.target.value })
    }

    const handleSleepwalkingProblem = (e: RadioChangeEvent) => {
        setFormData({ ...formData, sleepwalkingProblem: e.target.value })
    }

    const handleFirstOrAgain = (e: RadioChangeEvent) => {
        setFormData({ ...formData, firstOrAgain: e.target.value })
    }

    const handleClassTypeChange = (e: RadioChangeEvent) => {
        setFormData({ ...formData, classType: e.target.value })
    }

    const handleDoesHaveASportLicence = (e: RadioChangeEvent) => {
        setFormData({ ...formData, doesHaveASportLicence: e.target.value })
    }

    const handleHaveEverJoinedASummerCampBefore = (e: RadioChangeEvent) => {
        setFormData({ ...formData, haveEverJoinedASummerCampBefore: e.target.value })
    }

    const handleDoesHaveFearOfSea = (e: RadioChangeEvent) => {
        setFormData({ ...formData, doesHaveFearOfSea: e.target.value })
    }

    const isFormValid = (obj: any): boolean => {
        for (const [key, value] of Object.entries(obj)) {
            if (!nonRequiredFields.includes(key) && (!value || value === '')) {
                console.log(key)
                return false
            }
            console.log(key, value)
        }

        return true
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        async function sendFormData() {
            if (triggerRequest) {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                try {
                    // removing null values because graphql accepts them as null when they are required values
                    const formObject = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != ''))
                    // populating non required values with empty strings in order to be accepted them as empty strings
                    nonRequiredFields.forEach((field) => {
                        if (!formObject[field]) formObject[field] = ''
                    })

                    if (!isFormValid(formObject)) throw 'form is not valid'

                    setIsButtonDisabled(true)
                    const data = await client.request(Mutations.PutRegisteration, {
                        id,
                        ...formObject,
                    })

                    setVisiblePopup(true)
                    if (visiblePopup === true) {
                        setVisiblePopup(false)
                        navigate('/applications')
                    }
                } catch (error) {
                    setIsButtonDisabled(false)
                    setFormNotValid(true)
                    setTriggerRequest(false)
                }
            }
        }

        sendFormData()
    }, [triggerRequest])

    useEffect(() => {
        // Populate the form fields with the data
        // ideasAndThoughts.current!.input!.value = 'AJSDGJADSKGJ'
        // importantInfoAboutStudent.current!.input!.value = 'ASJDGASDKFDASGJ'
    }, [formData])

    useEffect(() => {
        const fetchData = async () => {
            try {
                client.setHeader('authorization', localStorage.getItem('idToken')!)
                const response = await client.request(Queries.GetApplication, {
                    ids: id,
                })
                setFormData(response.getApplication[0])
            } catch (e: any) {
                throw new Error(e)
            }
        }
        fetchData()
    }, [])

    const nonRequiredFields = ['previousExperience', 'whichSport', 'whereWasCamp', 'ifOptimistWhereWhatDegree', 'ifLaserWhereWhatDegree']

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            if (checked) {
                // setError('')
                setIsButtonDisabled(true)

                setFormData({
                    ...formData,
                    citizenId: citizenId.current!.input!.value,
                    birthPlace: birthPlace.current!.input!.value,

                    school: school.current!.input!.value,
                    grade: grade.current!.input!.value,
                    city: city.current!.input!.value,

                    address: address.current!.input!.value,
                    homePhone: homePhone.current!.input!.value,
                    studentPhone: studentPhone.current!.input!.value,
                    studentEmail: studentEmail.current!.input!.value,
                    motherNameSurname: motherNameSurname.current!.input!.value,
                    fatherNameSurname: fatherNameSurname.current!.input!.value,

                    bloodType: bloodType.current!.input!.value,
                    height: height.current!.input!.value,
                    weight: weight.current!.input!.value,
                    studentAllergy: studentAllergy.current!.input!.value,
                    studentAllergicFoods: studentAllergicFoods.current!.input!.value,
                    studentHealthIssues: studentHealthIssues.current!.input!.value,
                    studentUseOfMedicine: studentUseOfMedicine.current!.input!.value,
                    studentSideEffectMedicine: studentSideEffectMedicine.current!.input!.value,

                    whichSport: whichSport.current!.input!.value,
                    whereWasCamp: whereWasCamp.current!.input!.value,
                    ifOptimistWhereWhatDegree: ifOptimistWhereWhatDegree.current!.input!.value,
                    ifLaserWhereWhatDegree: ifLaserWhereWhatDegree.current!.input!.value,

                    parentName: parentName.current!.input!.value,
                    parentSurname: parentSurname.current!.input!.value,
                    parentCloseness: parentCloseness.current!.input!.value,
                    parentCitizenId: parentCitizenId.current!.input!.value,
                    parentJob: parentJob.current!.input!.value,
                    parentHomeAddress: parentHomeAddress.current!.input!.value,
                    parentWorkAddress: parentWorkAddress.current!.input!.value,
                    parentHomePhone: parentHomePhone.current!.input!.value,
                    parentWorkPhone: parentWorkPhone.current!.input!.value,
                    parentEmail: parentEmail.current!.input!.value,
                    motherPhone: motherPhone.current!.input!.value,
                    fatherPhone: fatherPhone.current!.input!.value,

                    invoiceName: invoiceName.current!.input!.value,
                    invoiceAddress: invoiceAddress.current!.input!.value,
                    taxAddress: taxAddress.current!.input!.value,
                    taxNumber: taxNumber.current!.input!.value,

                    ideasAndThoughts: ideasAndThoughts.current!.input!.value,
                    importantInfoAboutStudent: importantInfoAboutStudent.current!.input!.value,
                })

                setTriggerRequest(true)

                // console.log(data)
            } else {
                setError('Kayıt Olabilmek İçin Metni Onaylayınız')
                setIsButtonDisabled(false)
            }
        } catch (e: any) {
            throw new Error(e)
        }
    }

    const handleClosePopup = () => {
        setVisiblePopup(false)
        navigate('/applications')
    }

    const onChangeBirthDate: DatePickerProps['onChange'] = (date, dateString) => {
        setFormData({ ...formData, birthDate: dateString })
    }

    const onParentBirthDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFormData({ ...formData, parentBirthDate: dateString })
        console.log(formData.parentBirthDate)
    }

    const onChangeTime: DatePickerProps['onChange'] = (date, dateString) => {
        setFormData({ ...formData, time: dateString })
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const errorJsx = (
        <>
            <Row>
                <Typography.Text type="danger" strong>
                    Bu formda doldurulabilir tüm alanlar zorunludur.
                </Typography.Text>
            </Row>
        </>
    )

    return (
        <div>
            <Typography.Title style={{ color: '#414445', textAlign: 'center', marginTop: '3rem', marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                ÖN KAYIT FORMU
            </Typography.Title>
            <Typography
                style={
                    screenWidth < 600
                        ? {
                              marginInline: '20px',
                          }
                        : screenWidth < 1000
                        ? {
                              marginInline: '70px',
                          }
                        : {
                              marginInline: '250px',
                          }
                }></Typography>
            <Row justify="center" style={{ marginTop: '50px' }}>
                <Col
                    style={{ minWidth: '330px', justifyContent: 'center', textAlign: 'center' }}
                    xl={{ span: 15 }}
                    lg={{ span: 18 }}
                    md={{ span: 20 }}
                    sm={{ span: 20 }}
                    xs={{ span: 24 }}>
                    <Form onFinish={handleSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} labelAlign={'right'}>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            DÖNEM BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ pull: 1, span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Katılacağı Dönem</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item>
                                        <Input disabled style={{ background: 'white', color: 'black', minWidth: 280 }} value={formData.period} tabIndex={1} />
                                    </Form.Item>
                                </Row>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Konaklama Tipi</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item>
                                        <Radio.Group value={formData.boardingOrDay}>
                                            <Space>
                                                <Radio tabIndex={3} value={'day'}>
                                                    Gündüzlü
                                                </Radio>
                                                <Radio tabIndex={4} value={'boarding'}>
                                                    Yatılı
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Eğitim Sınıfı</div>
                                </Row>
                                <Row justify="space-around" align="top">
                                    <Form.Item style={{ fontWeight: 'bold' }}>
                                        <Radio.Group value={formData.classType} onChange={handleClassTypeChange}>
                                            <Space>
                                                <Radio style={{ fontWeight: 'normal' }} value={'sail'}>
                                                    Yelken
                                                </Radio>
                                                <Radio style={{ fontWeight: 'normal', whiteSpace: 'nowrap' }} value={'windSurf'}>
                                                    Rüzgar Sörfü
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>

                                <Row justify="space-around" align="top">
                                    <div style={{ fontWeight: 'bold' }}>Katılım Durumu</div>
                                </Row>

                                <Row justify={'center'} style={{ marginTop: '5px' }}>
                                    <Form.Item>
                                        <Radio.Group value={formData.firstOrAgain} onChange={handleFirstOrAgain}>
                                            <Space>
                                                <Radio tabIndex={5} value={'firstTime'}>
                                                    İlk
                                                </Radio>
                                                <Radio tabIndex={6} value={'experienced'}>
                                                    Tekrar
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal"></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            KİMLİK BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item rules={[{ required: true, message: 'Bu alan zorunludur.' }]} style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input tabIndex={7} type="text" name="name" value={formData.name} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input tabIndex={8} type="text" name="surname" value={formData.surname} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Tarihi">
                                    <Input value={formData.birthDate} disabled style={{ background: 'white', color: 'black' }} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Cinsiyeti">
                                    <Select value={formData.sex}>
                                        <Select.Option value="male">Erkek</Select.Option>
                                        <Select.Option value="female">Kız</Select.Option>
                                        tabIndex={9}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="TC Kimlik No">
                                    <Input tabIndex={10} type="text" name="citizenId" ref={citizenId} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Yeri">
                                    <Input tabIndex={12} type="text" name="birthPlace" ref={birthPlace} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            OKUL BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Okulu">
                                    <Input tabIndex={12} type="text" name="school" ref={school} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İli">
                                    <Input tabIndex={14} type="text" name="city" ref={city} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 2 }} lg={{ span: 12, pull: 2 }} xl={{ span: 12, pull: 2 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Sınıfı">
                                    <Input tabIndex={13} type="text" name="grade" ref={grade} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            İLETİŞİM BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Yaşadığı Ev Adresi">
                                    <Input tabIndex={15} type="text" name="address" ref={address} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Ev Telefonu">
                                    <Input tabIndex={16} type="text" name="homePhone" ref={homePhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin E-posta Adresi">
                                    <Input tabIndex={17} type="text" name="studentEmail" ref={studentEmail} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Kursiyerin Cep Telefonu">
                                    <Input tabIndex={18} type="text" name="studentPhone" ref={studentPhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Anne Adı Soyadı">
                                    <Input tabIndex={19} type="text" name="motherNameSurname" ref={motherNameSurname} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Baba Adı Soyadı">
                                    <Input tabIndex={20} type="text" name="fatherNameSurname" ref={fatherNameSurname} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            SAĞLIK BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col span={24}>
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Kilosu">
                                            <Input tabIndex={21} type="text" name="weight" ref={weight} />
                                        </Form.Item>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Kan Grubu">
                                            <Input tabIndex={23} type="text" name="bloodType" ref={bloodType} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 2 }} lg={{ span: 24, pull: 2 }} xl={{ span: 12, pull: 1 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Boyu (cm)">
                                            <Input tabIndex={22} type="text" name="height" ref={height} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row style={{ paddingTop: '3rem' }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            style={{ fontWeight: 'bold' }}
                                            label="Alerjik olduğu veya sakıncalı yiyecekler">
                                            <Input tabIndex={23} type="text" name="studentAllergicFoods" ref={studentAllergicFoods} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Kullandığı İlaçlar">
                                            <Input tabIndex={24} type="text" name="studentUseOfMedicine" ref={studentUseOfMedicine} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Alerjileri">
                                            <Input tabIndex={25} type="text" name="studentAllergy" ref={studentAllergy} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Sağlık Problemleri">
                                            <Input tabIndex={26} type="text" name="studentHealthIssues" ref={studentHealthIssues} />
                                        </Form.Item>
                                        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyerin Kullanması Sakıncalı İlaçlar">
                                            <Input tabIndex={27} type="text" name="studentSideEffectMedicine" ref={studentSideEffectMedicine} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Anne & Baba Beraber mi?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.parentsMarried} onChange={handleParentsMarried}>
                                                    <Space>
                                                        <Radio tabIndex={29} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={30} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Ailesinden İlk Ayrılışı mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.firstTimeAway} onChange={handleFirstTimeAway}>
                                                    <Space>
                                                        <Radio tabIndex={31} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={32} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Yatak Islatma Problemi Var mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.bedwettingProblem} onChange={handleBedwettingProblem}>
                                                    <Space>
                                                        <Radio tabIndex={33} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={34} value={'0'}>
                                                            Hayıt
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Uyurgezerlik Problemi Var mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.sleepwalkingProblem} onChange={handleSleepwalkingProblem}>
                                                    <Space>
                                                        <Radio tabIndex={35} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={36} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            SPOR VE DENİZ GEÇMİŞİ
                        </Typography.Title>
                        <Row style={{ marginTop: 16, background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24 }}>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Herhangi Bir Spor Dalında Lisansı Var mı?</div>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.doesHaveASportLicence} onChange={handleDoesHaveASportLicence}>
                                                    <Space>
                                                        <Radio tabIndex={37} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={38} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Hangi Spor?">
                                            <Input tabIndex={39} type="text" name="whichSport" ref={whichSport} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Daha Önce Herhangi Bir Yaz Kampına Katıldı mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.haveEverJoinedASummerCampBefore} onChange={handleHaveEverJoinedASummerCampBefore}>
                                                    <Space>
                                                        <Radio tabIndex={40} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={41} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Nerede?">
                                            <Input tabIndex={42} type="text" name="whereWasCamp" ref={whereWasCamp} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                        <Row justify="space-around" align="top">
                                            <div style={{ fontWeight: 'bold' }}>Deniz Korkusu Var mı?</div>
                                        </Row>

                                        <Row justify={'center'} style={{ marginTop: '5px' }}>
                                            <Form.Item>
                                                <Radio.Group value={formData.doesHaveFearOfSea} onChange={handleDoesHaveFearOfSea}>
                                                    <Space>
                                                        <Radio tabIndex={43} value={'1'}>
                                                            Evet
                                                        </Radio>
                                                        <Radio tabIndex={44} value={'0'}>
                                                            Hayır
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Hiç tekneye bindi mi?">
                                            <Select tabIndex={47} style={{ minWidth: 280 }} value={formData.haveEverRideABoat} onChange={handleEverRideBot}>
                                                <Select.Option value="bigBoat">Daha Büyük Tekneye Bindi</Select.Option>
                                                <Select.Option value="neverRide">Hiç Tekneye Binmedi</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Yüzme derecesi nedir?">
                                            <Select tabIndex={47} style={{ minWidth: 280 }} value={formData.swimmingDegree} onChange={handleSwimmingDegree}>
                                                <Select.Option value="cantSwim">Hiç yüzemiyor</Select.Option>
                                                <Select.Option value="swimsWithLifeJacket">Kolluk Can Yeleği İle Yüzüyor</Select.Option>
                                                <Select.Option value="swimsButCantDiveHead">Yüzüyor ama kafasını suya sokmaktan korkuyor</Select.Option>
                                                <Select.Option value="canSwim">Yüzüyor, dalıyor, kafasını suya sokmaktan korkmuyor</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Optimist Yaptıysa, Ne Derece / Nerede?">
                                            <Input tabIndex={48} type="text" name="ifOptimistWhereWhatDegree" ref={ifOptimistWhereWhatDegree} />
                                        </Form.Item>
                                        <Form.Item style={{ fontWeight: 'bold' }} label="Laser Yaptıysa, Ne Derece / Nerede? ">
                                            <Input tabIndex={49} type="text" name="ifLaserWhereWhatDegree" ref={ifLaserWhereWhatDegree} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            VELİ - VASİ BİLGİLERİ
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 1 }} lg={{ span: 12, pull: 1 }} xl={{ span: 12, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adı">
                                    <Input tabIndex={53} type="text" name="parentName" ref={parentName} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Soyadı">
                                    <Input tabIndex={55} type="text" name="parentSurname" ref={parentSurname} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="TC Kimlik Numarası">
                                    <Input tabIndex={57} type="text" name="parentCitizenId" ref={parentCitizenId} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Doğum Tarihi">
                                    <DatePicker format={'DD-MM-YYYY'} locale={locale} onChange={onParentBirthDateChange} />{' '}
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Yakınlık Derecesi">
                                    <Input tabIndex={59} type="text" name="parentCloseness" ref={parentCloseness} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Email">
                                    <Input tabIndex={61} type="text" name="parentEmail" ref={parentEmail} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Mesleği">
                                    <Input tabIndex={63} type="text" name="parentJob" ref={parentJob} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, pull: 0 }} lg={{ span: 12, pull: 0 }} xl={{ span: 12 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İş Telefonu">
                                    <Input tabIndex={54} type="text" name="parentWorkPhone" ref={parentWorkPhone} />
                                </Form.Item>

                                <Form.Item style={{ fontWeight: 'bold' }} label="Ev Adresi">
                                    <Input tabIndex={58} type="text" name="parentHomeAddress" ref={parentHomeAddress} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Ev Telefonu">
                                    <Input tabIndex={60} type="text" name="parentHomePhone" ref={parentHomePhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Anne Cep">
                                    <Input tabIndex={64} placeholder="+90 532 123 45 67" type="text" name="motherPhone" ref={motherPhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Baba Cep">
                                    <Input tabIndex={66} type="text" placeholder="+90 532 123 45 67" name="fatherPhone" ref={fatherPhone} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="İş Adresi">
                                    <Input tabIndex={65} type="text" name="parentWorkAddress" ref={parentWorkAddress} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            FATURA BİLGİLERİ
                        </Typography.Title>
                        <Typography.Text
                            style={{
                                fontSize: 16,
                                color: 'black',
                                textAlign: 'left',
                                margin: 0,
                                marginLeft: 10,
                                marginBottom: 24,
                                fontWeight: 'bold',
                            }}>
                            Kamp ücretinin faturalandırılmasını istediğiniz bilgileri giriniz.
                        </Typography.Text>
                        <Row style={{ marginTop: 16, background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Faturanın Kesileceği İsim ">
                                    <Input tabIndex={67} type="text" name="invoiceName" ref={invoiceName} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Vergi Dairesi">
                                    <Input tabIndex={68} type="text" name="taxAddress" ref={taxAddress} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Adres">
                                    <Input tabIndex={69} type="text" name="invoiceAddress" ref={invoiceAddress} />
                                </Form.Item>
                                <Form.Item style={{ fontWeight: 'bold' }} label="Vergi Numarası / TC Kimlik Numarası ">
                                    <Input tabIndex={70} type="text" name="taxNumber" ref={taxNumber} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Typography.Title
                            level={5}
                            style={{ color: '#414445', textAlign: 'left', margin: 0, marginLeft: 10, marginBottom: 24, fontWeight: 'bold', textDecoration: 'underline' }}>
                            FİKİR VE DÜŞÜNCELER
                        </Typography.Title>
                        <Row style={{ background: '#f39200', padding: '3rem', borderRadius: '1rem' }}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Veli Fikir ve Düșünceleri ">
                                    <Input tabIndex={71} type="text" name="ideasAndThoughts" ref={ideasAndThoughts} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24, pull: 1 }} lg={{ span: 24, pull: 1 }} xl={{ span: 24, pull: 1 }}>
                                <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ fontWeight: 'bold' }} label="Kursiyer Hakkında Önemli Ön Bilgiler ">
                                    <Input tabIndex={72} type="text" name="importantInfoAboutStudent" ref={importantInfoAboutStudent} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider type="horizontal" style={{}}></Divider>
                        <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Typography style={{ color: '#f39200', fontSize: '30px', fontWeight: 'bold' }}>ONAY</Typography>
                        </Col>
                        <Col style={{ display: 'flex', marginTop: '30px' }}>
                            <Checkbox tabIndex={73} onKeyDown={handleKeyDown} type="checkbox" onChange={handleCheckbox} checked={checked} style={{ marginRight: '15px' }} />
                            <Typography style={{ textAlign: 'left' }}>
                                Formda verdiğimiz bilgiler doğru ve eksiksizdir. Bu formda yazılı bilgilerde değișiklik olduğu taktirde kamp yönetimi tekrar bilgilendirilecektir.
                                Kamp ve kurslar süresince yapılacak eğitim, çalıșmalar, aktiviteler konusunda detaylı bilgi aldık. Bu ön kaydımızın, tarafımıza gönderilecek olan
                                ‘Katılım sözleșmesi ve Kamp kuralları’ evrakının imzalanarak onaylanması, ve ön kayıt ücretinin ödenmesi sonrasında gerçekleșeceğinin bilincindeyiz.
                                Global Sailing Academy Genç Yelkenci Yetiștirme Kampına katılacak olan velayetimiz/vesayetimiz altındaki küçüğün ruhsal ve fiziksel sağlığının hem
                                kendisi hem de kampa katılacak diğer kursiyerler ve kamp personeli açısından kampın bütünlüğünü bozacak engel hali yoktur, kampa katılmasına iznimiz
                                vardır. Onay metnini okudum ve kabul ediyorum.
                            </Typography>
                        </Col>
                        <Col style={{ marginTop: '20px', textAlign: 'left' }}>
                            <Form.Item>
                                <Text type="danger" strong>
                                    {error}
                                </Text>
                            </Form.Item>
                        </Col>
                        <Form.Item style={{ display: 'flex' }}>
                            <Row style={{ marginTop: '10px', display: 'flex' }}>
                                <Col>
                                    <Space size={'small'}>
                                        <Button
                                            tabIndex={74}
                                            type={'primary'}
                                            disabled={isButtonDisabled}
                                            htmlType="submit"
                                            title={checked ? '' : 'Kayıt Olabilmek İçin Metni Onaylayınız'}
                                            style={{ border: '1px solid #273D63', color: '#273D63', background: '#ffffff', marginTop: '20px', marginBottom: '40px' }}>
                                            Onaya Gönder
                                        </Button>

                                        <Modal visible={visiblePopup} footer={null} onCancel={handleClosePopup}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography.Title level={4}>Global Sailing Academy Online Kayıt Sistemi</Typography.Title>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography>Kaydınız alınmıştır.</Typography>
                                            </div>
                                        </Modal>
                                        <Popconfirm
                                            title={<p>Başvuruyu iptal etmek istediğinize emin misiniz?</p>}
                                            onConfirm={() => navigate('/applications')}
                                            okText="Evet"
                                            okButtonProps={{
                                                style: {
                                                    background: '#273D63',
                                                    color: '#ffffff',
                                                },
                                            }}
                                            cancelText="Hayır">
                                            <Button
                                                tabIndex={75}
                                                style={{ border: '1px solid #273D63', background: '#273D63', color: '#ffffff', marginTop: '20px', marginBottom: '40px' }}>
                                                İptal
                                            </Button>
                                        </Popconfirm>
                                    </Space>
                                    <Row>{formNotValid ? errorJsx : null}</Row>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default ApplicationForm
