import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/authContext'
import { Row, Col, Form, Input, Button, Typography, Modal, Checkbox } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
// import { ReCAPTCHA } from 'react-google-recaptcha'

const { Text, Title } = Typography

const SignUp: React.FC = () => {
    const navigate = useNavigate()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('+90')
    const [success, setSuccess] = useState('')
    const [created, setCreated] = useState(false)
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [checked, setChecked] = useState(false)

    const authContext = useContext(AuthContext)

    const signUpClicked = async () => {
        try {
            if (!checked) {
                throw new Error("Kayıt Olabilmek İçin KVKK Aydınlatma Metni'ni Onaylayınız")
            }
            await authContext.signUp(email, password, name, surname, phone.replace(/\s/g, ''))
            setCreated(true)
            setError('')
            setSuccess('Onaylama linki ' + email + ' adresine gönderildi!')
            setTimeout(() => {
                navigate('/signin')
            }, 2000)
        } catch (err: any) {
            switch (err.code) {
                case 'UsernameExistsException':
                    setError('Bu email adresi zaten kullanılıyor.')
                    break
                case 'InvalidParameterException':
                    setError('Lütfen geçerli bir email adresi ve başında ülke koduyla telefon numaranızı giriniz.')
                    break
                case 'InvalidPasswordException':
                    setError('Lütfen geçerli bir şifre giriniz.')
                    break
                default:
                    setError(err.message)
                    break
            }
        }
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const handleOpenModal = () => {
        setVisible(true)
    }

    const handleCloseModal = () => {
        setVisible(false)
    }

    const handleCheckbox = (e: any) => {
        setChecked(e.target.checked)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setChecked(!checked)
            event.preventDefault()
        }
    }

    const onCaptchaChange = (value: string) => {
        // Your logic to verify the response from CAPTCHA
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let phoneNumber = e.target.value

        if (phoneNumber.length < 1) {
            phoneNumber = '+'
        }
        if (!phoneNumber.startsWith('+')) {
            phoneNumber = '+' + phoneNumber
        }

        if (phoneNumber.length == 4 && phoneNumber.charAt(3) != ' ') {
            phoneNumber = phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 4)
        }

        if (phoneNumber.length == 8 && phoneNumber.charAt(7) != ' ') {
            phoneNumber = phoneNumber.slice(0, 7) + ' ' + phoneNumber.slice(7, 8)
        }

        if (phoneNumber.length == 12 && phoneNumber.charAt(11) != ' ') {
            phoneNumber = phoneNumber.slice(0, 11) + ' ' + phoneNumber.slice(11, 12)
        }

        if (phoneNumber.length > 16) phoneNumber = phoneNumber.slice(0, 16)

        setPhone(phoneNumber)
    }

    const errorJsx = (
        <>
            <Form.Item>
                <Text type="danger" strong>
                    {error}
                </Text>
            </Form.Item>
        </>
    )

    const successJsx = (
        <>
            <Form.Item style={{ position: 'relative' }}>
                <Text type="success" style={{ fontWeight: 'bold' }}>
                    {success}
                </Text>
            </Form.Item>
        </>
    )

    const signUpJsx = (
        <>
            {/* <ReCAPTCHA sitekey="your-site-key" onChange={onCaptchaChange} /> */}
            <Form
                name="normal_signup"
                className="signup-form"
                onFinish={signUpClicked}
                style={{
                    padding: '40px',
                    color: 'transparent',
                    backdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                    WebkitBackdropFilter: screenWidth < 1000 ? '' : 'blur(20px)',
                    backgroundClip: 'padding-box',
                    border: '1px solid white',
                    borderRadius: '2rem',
                    overflow: 'hidden',
                    height: 'auto',
                    marginBottom: '4rem',
                }}>
                <div style={screenWidth < 600 ? { display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '15px' }}>
                        <Form.Item name="name" rules={[{ required: true, message: 'Lütfen adınızı giriniz!' }]}>
                            <Input
                                placeholder="Ad"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                tabIndex={1}
                            />
                        </Form.Item>

                        <Form.Item name="email" rules={[{ required: true, message: 'Lütfen emil giriniz!' }]}>
                            <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} tabIndex={3} />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}>
                            <Input.Password placeholder="Şifre" onChange={(e) => setPassword(e.target.value)} tabIndex={5} />
                        </Form.Item>
                    </div>
                    <div style={{}}>
                        <Form.Item name="surname" rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' }]}>
                            <Input
                                placeholder="Soyad"
                                onChange={(e) => {
                                    setSurname(e.target.value)
                                }}
                                tabIndex={2}
                            />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: 'Telefon numaranızı girin!' }]}>
                            <Input
                                value={phone}
                                placeholder="Telefon Numarası"
                                // onChange={(e) => {
                                //     setPhone(e.target.value)
                                // }}
                                tabIndex={4}
                                type="text"
                                name="phone"
                                onChange={handlePhoneChange}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Lütfen şifrenizi onaylayın!' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject('Girilen iki şifre eşleşmiyor!')
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="Şifrenizi Onaylayın" tabIndex={6} />
                        </Form.Item>
                    </div>
                </div>
                {error ? errorJsx : null}
                {success ? successJsx : null}
                <Form.Item>
                    <div style={{ marginTop: '20px' }}></div>
                    <Link
                        to=""
                        type="link"
                        onClick={handleOpenModal}
                        style={{ textDecoration: 'underline', padding: 0, marginBottom: '10px', color: '#00004d', fontWeight: 'bold' }}
                        onMouseEnter={(e) => {
                            ;(e.target as HTMLLinkElement).style.color = '#f39200'
                        }}
                        onMouseLeave={(e) => {
                            ;(e.target as HTMLLinkElement).style.color = '#00004d'
                        }}>
                        KVKK Aydınlatma Metni
                    </Link>
                    <div style={{ marginBottom: '20px' }}></div>
                    <div key="checkbox" style={{ marginBottom: '10px' }}>
                        <Checkbox onKeyDown={handleKeyDown} type="checkbox" onChange={handleCheckbox} checked={checked} style={{ marginRight: '5px' }} tabIndex={7} />
                        <label htmlFor="checkbox" style={{ fontWeight: 'bold' }}>
                            KVKK şartlarını okudum, onaylıyorum.
                        </label>
                    </div>
                    <Modal
                        title="KVKK Aydınlatma Metni"
                        visible={visible}
                        onOk={handleCloseModal}
                        onCancel={handleCloseModal}
                        style={{ display: 'flex', overflow: 'hidden' }}
                        footer={null}>
                        <Typography>
                            GLOBAL SAİLİNG ACADEMY KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca, Şirketimiz tarafından,
                            Veri Sorumlusu sıfatıyla, kişisel verileriniz, iş amaçlarıyla bağlı olarak, aşağıda açıklandığı çerçevede kullanılmak, kaydedilmek, saklanmak,
                            güncellenmek, aktarılmak ve/veya sınıflandırılmak suretiyle işlenecektir. Bu kapsamda Şirketimiz tarafından başta özel hayatın gizliliği olmak üzere,
                            kişilerin temel hak ve özgürlüklerini korumak ve kişisel verilerin korunması amacıyla düzenlenen Kanun ve Yönetmelikler gereğince Şirketimiz, kişisel
                            verilerinizin hukuka aykırı olarak işlenmesini önleme, hukuka aykırı olarak erişilmesini önleme ve muhafazasını sağlama amacıyla, uygun güvenlik
                            düzeyini temin etmeye yönelik tüm teknik ve idari tedbirleri almaktadır. Bu metnin hedef kitlesi, Şirketimiz çalışanları veya Şirketimize iş başvurusu
                            yapmış olan çalışan adayları dışındaki, Şirketimiz tarafından kişisel verileri işlenen tüm gerçek kişilerdir. Veri sorumlusu sıfatıyla işlenen kişisel
                            verilere, burada belirtilenlerle sınırlı sayıda olmamak üzere aşağıda yer verilmektedir; İsim, soy isim, T.C. kimlik numarası, adres, telefon numarası,
                            e-posta adresi, imza, fiziksel mekan/güvenlik görüntü kaydı, çağrı merkezi/hizmet kalitesi ses kaydı, banka hesap numarası, cookie kayıtları Kişisel
                            verilerin işlenme amaçları ve hukuki sebepleri; Tarafınızca paylaşılan kişisel verileriniz; Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri
                            ve/veya temsil ettiğiniz kurum ve kuruluşları faydalandırmak için, Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması, pazarlama
                            faaliyetlerinin yapılması, iş geliştirme ve planlama faaliyetlerinin gerçekleştirilmesi dahil ve fakat bunlarla sınırlı olmamak üzere gerekli
                            çalışmaların yürütülmesi, Şirketimiz tarafından yürütülen iletişime yönelik idari operasyonların yürütülmesi, Şirketimizin kullanımda olan lokasyonların
                            fiziksel güvenliğinin ve denetiminin sağlanması, İş ortağı/müşteri/tedarikçi (yetkili veya çalışanları) ilişkilerinin kurulması, İş ortaklarımız,
                            tedarikçilerimiz veya sair üçüncü kişilerle birlikte sunulan ürün ve hizmetlere ilişkin sözleşme gereklerinin ve finansal mutabakatın sağlanması,
                            Şirketimizin insan kaynakları politikalarının yürütülmesi, Şirketimizin çağrı merkezinin aranması veya internet sayfasının kullanılması ve/veya
                            Şirketimizin düzenlediği eğitim, seminer veya organizasyonlara katılım sağlanması amacıyla işlenecektir. Kişisel verilerin toplanma ve saklanma yöntemi;
                            Şirketimizle paylaştığınız kişisel verileriniz, otomatik ya da otomatik olmayan yöntemlerle, ofisler, şubeler, çağrı merkezi, internet sitesi, sosyal
                            medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilir. Kişisel verileriniz elektronik ve/veya
                            fiziksel ortamlarda saklanacaktır. Şirketimiz tarafından temin edilen ve saklanan kişisel verilerinizin saklandıkları ortamlarda yetkisiz erişime maruz
                            kalmamaları, manipülasyona uğramamaları, kaybolmamaları ve zarar görmemeleri amacıyla gereken iş süreçlerinin tasarımı ile teknik güvenlik altyapı
                            geliştirmeleri uygulanmaktadır. Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında kullanılmamak kaydı ile gerekli tüm bilgi güvenliği
                            tedbirleri de alınarak işlenecek ve yasal saklama süresince veya böyle bir süre öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak
                            ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme, yok edilme ya da anonimleştirme yöntemleri ile Şirketimizin veri akışlarından
                            çıkarılacaktır. Kişisel Verilerin aktarılması; Kişisel verileriniz, Kanunlar ve sair mevzuat kapsamında ve açıklanan amaçlarla; Türk Telekomünikasyon
                            A.Ş. ve Türk Telekomünikasyon grup şirketlerine, Yetki vermiş olduğumuz, Şirketimiz nam ve hesabına faaliyette bulunan şirketler, temsilcilerimize,
                            Düzenleyici ve denetleyici kurumlara, kişisel verilerinizi tabi olduğu kanunlarında açıkça talep etmeye yetkili olan kamu kurum veya kuruluşlara,
                            Belirtilen amaçlar kapsamında iş ortaklıkları, tedarikçi ve yüklenici şirketler, bankalar, kredi risk ve finans kuruluşları ve sair gerçek veya tüzel
                            kişilere, Vergi ve benzeri danışmanlara, yasal takip süreçleri ile ilgili zorunlu kişilere, kurum ve kuruluşlara ve denetimciler de dâhil olmak üzere
                            danışmanlık aldığımız üçüncü kişilere ve bunlarla sınırlı olmaksızın, yurt içinde ve yurt dışında, yukarıda belirtilen amaçlarla iş ortakları, hizmet
                            alınan üçüncü kişi, yetkilendirilen kişi ve kuruluşlara aktarılabilecektir. KVKK’nın 11. maddesi gereği haklarınız; Şirketimize başvurarak, kişisel
                            verilerinizin; İşlenip işlenmediğini öğrenme, İşlenmişse bilgi talep etme, İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme, Yurt
                            içinde / yurt dışında aktarıldığı 3. kişileri bilme, Eksik / yanlış işlenmişse düzeltilmesini isteme, KVKK’nın 7. maddesinde öngörülen şartlar
                            çerçevesinde silinmesini / yok edilmesini isteme, Aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f) bentleri uyarınca yapılan işlemlerin
                            bildirilmesini isteme, Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme, Kanuna aykırı
                            olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarına sahipsiniz. KVK Kanunu’nun 13. maddesinin 1. fıkrası
                            gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı olarak veya Kişisel Verileri Koruma Kurulu’nun belirlediği diğer
                            yöntemlerle Şirketimize iletebilirsiniz.
                        </Typography>
                    </Modal>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="signup-form-button"
                        title={checked ? '' : "Kayıt Olabilmek İçin KVKK Aydınlatma Metni'ni Onaylayınız"}
                        style={{ background: '#f39200' }}
                        tabIndex={8}
                        block>
                        Kayıt Ol
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Link
                        to="/signin"
                        style={{ color: '#00004d', textAlign: 'center', fontWeight: 'bolder', textDecoration: 'underline' }}
                        onMouseEnter={(e) => {
                            ;(e.target as HTMLLinkElement).style.color = '#f39200'
                        }}
                        onMouseLeave={(e) => {
                            ;(e.target as HTMLLinkElement).style.color = '#00004d'
                        }}>
                        Giriş Ekranına Dön
                    </Link>
                </Form.Item>
            </Form>
        </>
    )
    return (
        <Row justify="center" align="middle" style={{}}>
            <Col>
                <Title style={screenWidth < 1000 ? { textAlign: 'center', fontSize: '35px' } : { textAlign: 'center', marginTop: '10rem', fontSize: '35px' }}>Kayıt Ol</Title>
                {!created ? signUpJsx : signUpJsx}
            </Col>
        </Row>
    )
}

export default SignUp
